<template>
  <div class="container">
    <div style="text-align: end; padding-bottom: 10px">
      <el-button class="el-icon-document-add" type="primary" @click="handleAdd">新增</el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" stripe :height="elementHeight">
        <el-table-column type="index" label="序号" width="180"></el-table-column>
        <el-table-column prop="plate" label="模块名称"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button circle type="warning" icon="el-icon-edit" @click="editItem(scope.row)"></el-button>
            <el-button circle type="danger" icon="el-icon-delete" @click="deleteItem(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Dialog
      :showDialog="showDialog"
      @handleClose="cancle"
      @handleReqData="reqData"
      :title="isAdd ? '添加模块' : '修改模块'"
      width="350px"
    >
      <div>
        <!-- <div class="_Inp">
          <span>公司：</span>
          <el-select v-model="compId" placeholder="请选择公司" style="width: 100%">
            <el-option
              v-for="item in option"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>-->
        <div class="_Inp">
          <span>模块名称：</span>
          <el-input placeholder="请输入" v-model="form.plate" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
export default {
  components: { Dialog },
  data() {
    return {
      elementHeight:0,
      tableData: [],
      showDialog: false,
      isAdd: true,
      option: [],
      compId: "",
      form: {
        plate: "",
      },
    };
  },
  created() {
    this.getElementHeight();
    this.getList();
    this.option = this.$store.state.companyList;
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - 135;
      });
    },
    getList() {
      get("/api/Plate").then((res) => {
        this.tableData = res.data;
      });
    },
    handleAdd() {
      this.showDialog = true;
      this.form.plate = "";
    },
    cancle() {
      this.showDialog = false;
    },
    editItem(row) {
      this.isAdd = false;
      this.showDialog = true;
      this.form = row;
    },
    reqData() {
      if (this.isAdd) {
        let data = {
          id: 0,
          plate: this.form.plate,
          tenantId: JSON.parse(sessionStorage.getItem("userInfo")).tenantId,
        };
        post("/api/Plate", data).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.showDialog = false;
            this.getList();
          }
        });
      } else {
        put("/api/Plate", this.form).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.showDialog = false;
            this.getList();
          }
        });
      }
    },
    deleteItem(row) {
      this.$confirm("此操作将删除模块, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod("/api/Plate?id=" + row.id).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>